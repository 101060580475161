import React, { useRef, useEffect } from "react";
import Typed from "typed.js";

const TypeEffect = () => {
  const typeTarget = useRef(null);

  useEffect(() => {
    const typed = new Typed(typeTarget.current, {
      strings: [
        "<i>Think</i>",
        "<i>Draw</i>",
        "<i>Plan</i>",
        "<i>Make</i>",
        "<i>*Game Crashes*</i>",
        "<i>Try Again</i>",
      ],
      typeSpeed: 100,
      backSpeed: 100,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return <span ref={typeTarget} />;
};

export default TypeEffect;
