import "./App.css";
import React, { Component, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import TheGemDev from "./TheGemDev";
import GDevelop from "./pages/TheGemDev/gdevelop";
import Godot from "./pages/TheGemDev/godot";

import ReactGA from "react-ga";
const TRACKING_ID = "UA-184993469-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <Routes>
        <Route path='/' element={<TheGemDev />} exact />
        <Route path='/gdevelop' element={<GDevelop />} />
        <Route path='/godot' element={<Godot />} />
        {/* <Route path='/hire-me' element={<TheGemDev />} /> */}
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    </>
  );
}

export default App;
