import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HubspotForm from "react-hubspot-form";
import { faMessage, faTruckFast } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import useAnalyticsEventTracker from "../useAnalyticsEventTracker";

function Section3() {
  const gaEventTracker = useAnalyticsEventTracker("Contact us");
  return (
    <div className='bg-gradient-to-b from-[#fff] to-[#454e56]'>
      <div className='hero min-h-screen '>
        <div className='hero-content lg:gap-20 flex-col lg:flex-row-reverse'>
          <div className='text-center lg:text-left'>
            <h1 className='text-5xl font-bold'>Do you have a question?</h1>
            <h3 className='py-6 text-1xl App-Email-sm '>👇 Send an email</h3>
            <h3 className='py-6 text-1xl App-Email-lg hidden'>
              👈 Send an email
            </h3>
          </div>
          <div className='card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100'>
            <div className='card-body'>
              <HubspotForm
                portalId='26560680'
                region='eu1'
                formId='d4754172-b49a-4aa2-81fb-f9cf6cdeeee8'
                onSubmit={() => {
                  console.log("Submit!");
                  gaEventTracker("Email Submit");
                }}
                onReady={(form) => console.log("Form ready!")}
                loading={<div>Loading...</div>}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='stats stats-vertical lg:stats-horizontal'></div>
    </div>
  );
}

export default Section3;
