import React from "react";
import Body from "./body";
import Header from "../../Header";
import Footer from "../../Footer";

function Godot() {
  return (
    <>
      <Header />
      <Body />
      <Footer />
    </>
  );
}

export default Godot;
