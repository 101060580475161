import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import Section1 from "./Section1";
import { Link, useNavigate } from "react-router-dom";
import TypeEffect from "./TypeEffect";
import Section2 from "./Section2";
import Section3 from "./Section3";
import useAnalyticsEventTracker from "../useAnalyticsEventTracker";

function Body() {
  const navigate = useNavigate();
  const gaEventTracker = useAnalyticsEventTracker("Contact us");

  return (
    <div>
      <div className='hero min-h-screen bg-white '>
        <div className='hero-content flex-col sm:flex-row-reverse'>
          <div className='text-center lg:text-left'>
            <h1 className='text-5xl font-bold'>
              [ <TypeEffect /> ]
            </h1>

            <p className='py-6'>
              The Gem Dev Uploads Game Development tutorials on [{" "}
              <FontAwesomeIcon icon={faYoutube} />{" "}
              <a
                className='link link-error'
                href='https://youtube.com/@thegemdev'>
                Youtube
              </a>{" "}
              ]
            </p>
            <div className='btn-group btn-group-horizontal '>
              <button className='btn' onClick={() => navigate("/gdevelop")}>
                GDevelop
              </button>
              <div className='divider divider-horizontal'></div>

              <button className='btn' onClick={() => navigate("/godot")}>
                Godot
              </button>

              <div className='divider divider-horizontal'></div>

              <button className='btn btn-outline btn-primary'>
                <a
                  href='https://www.fiverr.com/thegemdev'
                  onClick={() => gaEventTracker("Hire Me")}>
                  Hire Me
                </a>
              </button>
            </div>
          </div>
          <div className='flex flex-col w-full border-opacity-50 BodyDivider'>
            <div className='divider'>More...</div>
          </div>
          <Section1 />
        </div>
      </div>

      <Section2 />
      <Section3 />
    </div>
  );
}

export default Body;
