import React from "react";
function Body() {
  return (
    <div className='hero min-h-screen bg-gradient-to-b from-[#fff] to-[#454e56] text-white'>
      <div className='hero-content text-center'>
        <div className='max-w-xl'>
          <div className='mockup-window border bg-primary border-primary'>
            <img
              src='https://github.com/godotengine/godot/raw/master/logo_outlined.svg'
              className='max-w-xxl rounded-lg shadow-2xl'
            />
            <p className='py-5 px-5'>
              It is a feature-packed, cross-platform game engine to create 2D and
              3D games from a unified interface
            </p>
          </div>
          <div className='divider'></div>
          <p className='py-6'>
            It provides a comprehensive set of common tools, so that users can
            focus on making games without having to reinvent the wheel. Games
            can be exported with one click to a number of platforms, including
            the major desktop platforms (Linux, macOS, Windows), mobile
            platforms (Android, iOS), as well as Web-based platforms and
            consoles.
          </p>

          <div
            onClick={() => {
              window.open("https://www.gdevelop.io");
            }}>
            <button className='btn bg-[#478cbf] hover:bg-[#000] border-[#478cbf] text-[#fff]'>
              Learn More...
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
