import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../img/logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import { faBlog } from "@fortawesome/free-solid-svg-icons";
import { faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import useAnalyticsEventTracker from "../useAnalyticsEventTracker";
function Header() {
  const gaEventTracker = useAnalyticsEventTracker("Contact us");
  const navigate = useNavigate();
  return (
    <div className='navbar fixed z-40 bg-[#454e56]/80 shadow-md bg-blend-multiply'>
      <div className='flex-none'></div>
      <div className='flex-1'>
        <a className='btn btn-circle btn-ghost'>
          <img
            src={logo}
            className='App-logo'
            alt='logo'
            onClick={() => navigate("/")}
          />
        </a>
      </div>
      <div className='gap-3'>
        <label tabIndex={1} className='btn gap-2 '>
          <FontAwesomeIcon icon={faBlog} fontSize='20px' />
          <a
            href='https://thegemdev.medium.com'
            onClick={() => gaEventTracker("Blog")}>
            Blog
          </a>
        </label>
        <div className='dropdown dropdown-bottom dropdown-end '>
          <label tabIndex={0} className='btn gap-2 '>
            <FontAwesomeIcon icon={faGooglePlay} fontSize='20px' />
            Apps
            <svg
              className='fill-current'
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 24 24'>
              <path d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' />
            </svg>
          </label>
          <ul
            tabIndex={0}
            className='dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52'>
            <li>
              <a
                href='https://play.google.com/store/apps/details?id=com.thegemdev.gdevelopremote'
                onClick={() => gaEventTracker("GDevelop App")}>
                GDevelop Remote
              </a>
            </li>
            <li>
              <a>More Coming soon...</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
