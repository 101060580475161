import YouTube, { YouTubeProps } from "react-youtube";
import React from "react";

function Section2() {
  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    // access to player in all event handlers via event.target
    event.target.stopVideo();
  };

  const opts: YouTubeProps["opts"] = {
    height: "210",
    width: "384",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  return (
    <>
      <div className='YoutubeVideos place-content-center gap-[40px]'>
        <div className='card w-96 bg-base-100 shadow-2xl text-clip '>
          <div class='youTubeContainer'>
            <YouTube
              videoId='YnuWDPB_xYs'
              opts={opts}
              onReady={onPlayerReady}
            />
          </div>
          <div className='card-body items-center text-center overflow-hidden'>
            <h2 className='card-title'>10 Tips And Tricks</h2>
            <p>This GDevelop tips which will save you a lot of time</p>
            <div
              className='card-actions'
              onClick={() => {
                window.open(
                  "https://www.youtube.com/watch?v=YnuWDPB_xYs&t=51s"
                );
              }}>
              <button className='btn btn-primary'>Watch Now</button>
            </div>
          </div>
        </div>
        <div className='card w-96 bg-base-100 shadow-2xl'>
          <div class='youTubeContainer'>
            <YouTube
              videoId='3cf5Z5l9gvk'
              opts={opts}
              onReady={onPlayerReady}
              style={{ borderRadius: "30px" }}
            />
          </div>
          <div className='card-body items-center text-center overflow-hidden'>
            <h2 className='card-title'>Create an online multiplayer</h2>
            <p>Start building your next online multiplayer game immediately</p>
            <div
              className='card-actions'
              onClick={() => {
                window.open("https://www.youtube.com/watch?v=3cf5Z5l9gvk");
              }}>
              <button className='btn btn-primary'>Watch Now</button>
            </div>
          </div>
        </div>
        <div className='card w-96 bg-base-100 shadow-2xl'>
          <div class='youTubeContainer'>
            <YouTube
              videoId='5TKBh_oQ5b4'
              opts={opts}
              onReady={onPlayerReady}
            />
          </div>
          <div className='card-body items-center text-center overflow-hidden'>
            <h2 className='card-title'>Top 10 Godot Games</h2>
            <p className=''>Some of the best Godot games of 2021</p>
            <div
              className='card-actions'
              onClick={() => {
                window.open(
                  "https://www.youtube.com/watch?v=5TKBh_oQ5b4&t=16s"
                );
              }}>
              <button className='btn btn-primary'>Watch Now</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Section2;
