function Section1() {
  return (
    <div className='stack'>
      <div className='card shadow-xl bg-primary text-primary-content '>
        <div className='card-body h-80'>
          <h2 className='card-title'></h2>
          <div className='mockup-code'>
            <pre data-prefix='1'>
              <code>npm i Game</code>
            </pre>
            <pre data-prefix='2'>
              <code>installing...</code>
            </pre>
            <pre data-prefix='3' className='bg-warning text-warning-content'>
              <code>Error!</code>
            </pre>
            <pre data-prefix='4'>
              <code>Retrying...</code>
            </pre>
            <pre data-prefix='5' className='bg-error text-error-content'>
              <code>Game Engine not found!</code>
            </pre>

            <pre data-prefix='6'>
              <code>Install a Game Engine</code>
            </pre>
            <pre data-prefix='7' className='bg-success text-success-content'>
              <code>
                Install{" "}
                <a className='link' href='https://gdevelop.io/'>
                  GDevelop!
                </a>{" "}
              </code>
            </pre>
          </div>
        </div>
      </div>
      <div className='card shadow bg-primary text-primary-content'>
        <div className='card-body h-80'>
          <h2 className='card-title'></h2>
          <p></p>
        </div>
      </div>
      <div className='card shadow-sm bg-primary text-primary-content'>
        <div className='card-body h-90'>
          <h2 className='card-title'></h2>
          <p></p>
        </div>
      </div>
    </div>
  );
}

export default Section1;
