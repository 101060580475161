import "./App.css";
import Header from "./pages/Header";
import Body from "./pages/Body";
import Footer from "./pages/Footer";
import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

function TheGemDev() {
  return (
    <div>
      <Header />
      <Body />
      <Footer />
    </div>
  );
}

export default TheGemDev;
