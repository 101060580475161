import React from "react";
function Body() {
  return (
    <div className='hero min-h-screen bg-gradient-to-b from-[#fff] to-[#454e56] text-white'>
      <div className='hero-content text-center'>
        <div className='max-w-xl'>
          <div className='mockup-window border bg-primary border-primary'>
            <img
              src='https://raw.githubusercontent.com/4ian/GDevelop/master/newIDE/GDevelop%20banner.png'
              className='max-w-xxl rounded-lg shadow-2xl'
            />
            <p className='py-5'>An Easy, Intuitive Way to Make Games</p>
          </div>
          <div className='divider'></div>
          <p className='py-6'>
            What makes GDevelop unique and so easy to use is the event system.
            Events are a powerful way to express the logic of your game, without
            having to learn a programming language.
          </p>

          <div
            onClick={() => {
              window.open("https://godotengine.org");
            }}>
            <button className='btn bg-[#230d3c] hover:bg-[#000] border-[#230d3c] text-[#fff]'>
              Learn More...
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
