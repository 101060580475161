import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Body from "./body";
import Header from "../../Header";
import Footer from "../../Footer";

function GDevelop() {
  return (
    <>
      <Header />
      <Body />
      <Footer />
    </>
  );
}

export default GDevelop;
